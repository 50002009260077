import React, { useContext, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Box, Input } from '../../Core';
import { Col, Row, FormControl } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { updateQueryParam } from '../../../utils/helperFn';
import GlobalContext from '../../../context/GlobalContext';
import Button from '../../../components/Core/Button';
import lockIcon from '../../../assets/image/svg/lock.svg';
import { prepareProductForLeadUpdate } from '../../../utils/OdinOrderHelpers';
import { RetrieveDefaultProduct } from '../../../utils/OdinPricingHelpers';
import APINO from '../../../api/api-no';
import {
  createLeadContactBillingAddressPayload,
  extractLeadIdFromAPIResponse,
  extractContactIdFromAPIResponse,
  extractAddressIdFromAPIResponse,
} from '../../../utils/OdinOrderHelpers';
import { TinySwitch } from '../../../components/Core/Switch';
import { emailRegex } from '../../../utils/helperFn';
import API from '../../../api/api';

const ResidentialForm = () => {
  const gContext: any = useContext(GlobalContext);
  const [isCreatingRecords, setIsCreatingRecords] = useState<boolean>(false);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [validPhoneNumber, setValidPhoneNumber] = useState(false)
  const [phoneChecked, setPhoneChecked] = useState(false)
  const [validEmailAddress, setValidEmailAddress] = useState(true) // false
  const [emailChecked, setEmailChecked] = useState(true) // false 
  let leadContactBillingAddress = []
  const axios = require('axios');
  let registrationData: any
  let leadID = null

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    updateQueryParam('step', '3')
  }, []);

  useEffect(() => {
    if (validPhoneNumber) { // && validEmailAddress

      // On Form submit generate Lead, Contact and Billing Address records
      const personalData = {
        firstName: gContext.goGetRegistrationData()?.personalData?.firstName,
        lastName: gContext.goGetRegistrationData()?.personalData?.lastName,
        email: gContext.goGetRegistrationData()?.personalData?.email,
        phoneNumber: gContext.goGetRegistrationData().personalData?.phoneNumberValidated,
        companyName: gContext.goGetRegistrationData()?.personalData?.companyName,
        companyPosition: gContext.goGetRegistrationData()?.personalData?.companyPosition,
        marketingConsent
      };

      const personalDataObj = {
        firstName: gContext.goGetRegistrationData()?.personalData?.firstName,
        lastName: gContext.goGetRegistrationData()?.personalData?.lastName,
        email: gContext.goGetRegistrationData()?.personalData?.email,
        phoneNumber: gContext.goGetRegistrationData().personalData?.phoneNumberValidated,
        companyName: gContext.goGetRegistrationData()?.personalData?.companyName,
        companyPosition: gContext.goGetRegistrationData()?.personalData?.companyPosition,
        marketingConsent
      }

      const payload = {
        ...gContext.goGetRegistrationData(),
        Source: 'NETOMNIA',
        offerId: gContext.goGetRegistrationData()?.offerId,
        FingerprintID: (typeof window !== 'undefined' && sessionStorage.getItem('FingerprintID')) ? sessionStorage.getItem('FingerprintID') : null,
      };

      const isBdukAndOrder = gContext.goGetRegistrationData()?.SearchPremiseResults.properties['IsBDUK'] === "true" &&
      gContext.goGetRegistrationData()?.SearchPremiseResults.properties['SalesStatus'] === 'ORDER';

      API
        /* 1. Get the offer passed from the Pricing section */
        .get('/ProductModule/v1.0/cst/Offer/active/RESIDENTIAL?filters=ContractType:CONTRACT,IsDefault:true')
        .then(response => {
          console.log('%c 1. Offer fetch success', 'color:limegreen', response)

          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            selectedProduct: RetrieveDefaultProduct(response.data.data.Product),
            Source: "WEBSITE",
            offerId: response.data.data.id
          });

          registrationData = {
            selectedProduct: RetrieveDefaultProduct(response.data.data.Product)
          };

          (isBdukAndOrder ? API : APINO).post(
            `/CrmModule/v1.0/db/batch`,
            createLeadContactBillingAddressPayload(payload, personalData, isBdukAndOrder ? 'YF' : 'NO'),
          )
            .then((response: any) => {
              setIsCreatingRecords(false);

              const leadId = extractLeadIdFromAPIResponse(response.data.data);
              leadID = leadId
              const contactId = extractContactIdFromAPIResponse(response.data.data);
              const addressId = extractAddressIdFromAPIResponse(response.data.data);

              const newState = {
                ...gContext.goGetRegistrationData(),
                leadId,
                contactId,
                addressId,
                personalDataObj,
                checkoutStep: 1,
              };

              gContext.goSetRegistrationData(newState);

              console.log('%c 2. Create Lead, Contact & Billing address success', 'color:limegreen', response)
              if (typeof window !== 'undefined' && window) {
                localStorage.removeItem('utmData');
              }
              leadContactBillingAddress = response.data.data

              console.log('prepareProductForLeadUpdate(registrationData.selectedProduct): ', prepareProductForLeadUpdate(registrationData.selectedProduct))
              console.log('registrationData.selectedProduct: ', registrationData.selectedProduct)

              return (isBdukAndOrder ? API : APINO).post(`/CrmModule/v1.0/db-associations/Lead/${leadId}?format=transformLevel2`,
                prepareProductForLeadUpdate(registrationData.selectedProduct)
              )
            })
            .then(() => {
              navigate("/success-generic/", {
                state: isBdukAndOrder ? {
                  pageData: {
                    leadID,
                    sprn: gContext.goGetRegistrationData()?.SearchPremiseResults?.properties['SPRN'],
                    directRedirectToYf: false
                  }
                } : undefined
              })
            })
            .catch((error) => {
              setIsCreatingRecords(false);
              // setEmailChecked(false)
              // setValidEmailAddress(false)
              setPhoneChecked(false)
              setValidPhoneNumber(false)
            });
        })
        .catch((error) => {
          setIsCreatingRecords(false);
          // setEmailChecked(false)
          // setValidEmailAddress(false)
          setPhoneChecked(false)
          setValidPhoneNumber(false)
        });
    }
  },
    [validPhoneNumber]) // , validEmailAddress

    const checkPhone = () => {
      setIsCreatingRecords(true);
      let phoneNumber = gContext.goGetRegistrationData().personalData?.phoneNumber;
  
      if (phoneNumber.indexOf('+44') !== -1) {
        phoneNumber = phoneNumber.replace('+44', '')
      }
  
      API.get(`CrmModule/twilio/v1.0/${'+44' + phoneNumber}/lookup`)
        .then((response) => {
          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            personalData: {
              ...gContext.goGetRegistrationData().personalData,
              phoneNumberValidated: response.data.data.phoneNumber
            }
          })
          setValidPhoneNumber(response.data.data.valid)
          if (!response.data.data.valid) {
            setIsCreatingRecords(false);
          }
          setPhoneChecked(true)
          
        })
        .catch((error) => {
          setValidPhoneNumber(false)
          setPhoneChecked(true)
          setIsCreatingRecords(false);
        })
    }

  // Deprecated, due to sendgrid token issue this is not implemented.
  const checkEmail = () => {

    API.post('/NotificationModule/v1.0/validation/email', {
      email: gContext.goGetRegistrationData()?.personalData?.email
    })
      .then((resp) => {
        const result = resp.data.data[0].body.result
        setEmailChecked(true)
        setValidEmailAddress(result == 'Invalid' ? false : true)
      })
      .catch((error) => {
        setValidEmailAddress(false)
        setEmailChecked(true)
        gContext.setAPIErrorMsg({
          title: 'Email validation error',
          message: error.response ? error.response?.data.message : error
        });
      });
  }

  const canNotContinue = () => {
    if (
      !gContext.goGetRegistrationData()?.personalData?.firstName ||
      !gContext.goGetRegistrationData()?.personalData?.lastName ||
      !gContext.goGetRegistrationData()?.personalData?.phoneNumber ||
      !gContext.goGetRegistrationData()?.personalData?.email ||
      !emailRegex.test(gContext.goGetRegistrationData()?.personalData?.email)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row className="justify-content-center mt-4">
      <Col sm={12}>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            companyPosition: '',
            marketingConsent: false
          }}
          onSubmit={() => {
            setFormSubmitted(true)
            if (canNotContinue()) {
              return
            }
            checkPhone()
            // checkEmail()
          }}
        >
          {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
            <Form>
              <Row>
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.firstName && <p className="invalid-label">Required field</p>}
                    <Input
                      disabled={isCreatingRecords && !canNotContinue()}
                      type=""
                      name="firstName"
                      placeholder="First name"
                      autocomplete="given-name"
                      value={gContext.goGetRegistrationData()?.personalData?.firstName}
                      onChange={e => {
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData()?.personalData,
                            firstName: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.lastName && <p className="invalid-label">Required field</p>}
                    <Input
                      type=""
                      disabled={isCreatingRecords && !canNotContinue()}
                      name="lastName"
                      placeholder="Last name"
                      autocomplete="family-name"
                      value={gContext.goGetRegistrationData()?.personalData?.lastName}
                      onChange={e => {
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData()?.personalData,
                            lastName: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>
              <Row className='justify-center'>
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.phoneNumber && <p className="invalid-label">Required field</p>}
                    {formSubmitted && !validPhoneNumber && !canNotContinue() && phoneChecked && <p className="invalid-label">Please enter a valid phone number</p>}
                    <Input
                      type=""
                      name="phoneNumber"
                      disabled={isCreatingRecords && !canNotContinue()}
                      autocomplete="tel"
                      placeholder="Phone Number"
                      value={gContext.goGetRegistrationData()?.personalData?.phoneNumber}
                      onChange={e => {
                        setPhoneChecked(false)
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData()?.personalData,
                            phoneNumber: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>
              <Row>
                {/* Show Quotes Button */}
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !emailRegex.test(gContext.goGetRegistrationData()?.personalData?.email) && <p className="invalid-label">Required field</p>}
                    {formSubmitted && !validEmailAddress && !canNotContinue() && emailChecked && <p className="invalid-label">Please enter a valid email address</p>}
                    <Input
                      type=""
                      name="email"
                      disabled={isCreatingRecords && !canNotContinue()}
                      autocomplete="email"
                      placeholder="Email"
                      value={gContext.goGetRegistrationData()?.personalData?.email}
                      onChange={e => {
                        // setEmailChecked(false)
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData()?.personalData,
                            email: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>
              {
                gContext.goGetRegistrationData()?.offer?.properties?.CustomerType === 'BUSINESS' &&
                <>
                  <Row>
                    <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                      <Box mb={3} className="position-relative">
                        {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.companyName && <p className="invalid-label">Required field</p>}
                        <Input
                          type=""
                          disabled={isCreatingRecords && !canNotContinue()}
                          name="companyName"
                          placeholder="Company name"
                          autocomplete="company-name"
                          value={gContext.goGetRegistrationData()?.personalData?.companyName}
                          onChange={e => {
                            gContext.goSetRegistrationData({
                              ...gContext.goGetRegistrationData(),
                              personalData: {
                                ...gContext.goGetRegistrationData()?.personalData,
                                companyName: e.target.value
                              }
                            })
                          }}
                          css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                        />
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                      <Box mb={3} className="position-relative">
                        {formSubmitted && !gContext.goGetRegistrationData()?.personalData?.companyPosition && <p className="invalid-label">Required field</p>}
                        <Input
                          type=""
                          disabled={isCreatingRecords && !canNotContinue()}
                          name="companyPosition"
                          placeholder="Company position"
                          autocomplete="company-position"
                          value={gContext.goGetRegistrationData()?.personalData?.companyPosition}
                          onChange={e => {
                            gContext.goSetRegistrationData({
                              ...gContext.goGetRegistrationData(),
                              personalData: {
                                ...gContext.goGetRegistrationData()?.personalData,
                                companyPosition: e.target.value
                              }
                            })
                          }}
                          css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                        />
                      </Box>
                    </Col>
                  </Row>
                </>
              }

              <Col sm={12} md={6} lg={4} className="m-auto pt-5">
                <Row >
                  <Col
                    xs={3}
                    className="pt-1 text-center text-lg-left switch-wrapper"
                  >
                    <TinySwitch setValue={(e: any) => setMarketingConsent(e)} />
                  </Col>
                  <Col xs={9} className="mb-4">
                    <span className="consent-info register-consent">
                      I understand and consent to Netomnia using my information above to update me
                      about offers, products and services in accordance with{' '}
                      <a
                        href="/legal/privacy-and-cookie-policy"
                        target="_blank"
                      ><span className='consent-info register-consent' style={{ color: '#ce0390', textDecoration: 'underline' }}>Netomnia’s Privacy Policy.</span></a>
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col sm={12} md={4} className="m-auto pt-4">
                <Button
                  className={`register-form-button w-100 ${(canNotContinue() || isCreatingRecords) ? 'invalid' : ''} ${marketingConsent ? '' : ' grayed-out'}`}
                  color={marketingConsent ? 'alternative' : 'black'}
                  onClick={handleSubmit}
                >
                  {
                    !canNotContinue() && isCreatingRecords
                      ? 'Please wait...'
                      :  gContext.goGetRegistrationData()?.SearchPremiseResults.properties['SalesStatus'] === 'ORDER' ?
                        'Show available packages' : 'Continue'
                  }
                </Button>
              </Col>

              {/* SSL Secure Icon */}
              <Col sm={12} className="text-center mt-3">
                <img
                  src={lockIcon}
                  alt=""
                  className="img-fluid"
                  style={{ maxWidth: '14px', color: 'black' }}
                />
                <span style={{ color: 'black', fontSize: '0.8em', marginLeft: 3 }}>SSL Secure</span>
              </Col>

              {/* Go Back */}
              {
                gContext.goGetRegistrationData()?.SearchPremiseResults?.properties?.SalesStatus === 'ORDER' &&
                <Col sm={12} className="text-center mt-5">
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      gContext.goSetRegistrationData({
                        ...gContext.goGetRegistrationData(),
                        registerStep: gContext.goGetRegistrationData().currentProvider === 'I_HAVE_NO_ACTIVE_SERVICE' ? 1 : 2
                      })
                    }
                  >
                    ← Go Back
                  </p>
                </Col>
              }
            </Form>
          )}
        </Formik>
      </Col>
    </Row >
  );
};

export default ResidentialForm;
